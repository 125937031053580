import { Component, OnInit ,Output,EventEmitter} from '@angular/core';

@Component({
  selector: 'bm-about-us-selector',
  templateUrl: './about-us-selector.component.html',
  styleUrls: ['./about-us-selector.component.scss']
})
export class AboutUsSelectorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  @Output() pressed = new EventEmitter<string>();
  
  onPressed(text:string){
    console.log('selector' + text)
    this.pressed.emit(text)
  }
}
