import { Component, Input, OnInit } from '@angular/core';
import {Router } from '@angular/router';

@Component({
  selector: 'bm-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  constructor(private route:Router) { }
  @Input() label: string;

  @Input() bgColor: string ='button';
  
  @Input() path:string;
  @Input() externalLink:string="";
  ngOnInit(): void {

  }
  onClick() {
    
    if (this.externalLink != "") {
      window.open(this.externalLink);
    }else {
      this.route.navigateByUrl(this.path);
    }
  }
}
