<div id="ab-card">
    <h3 id="ht">
        {{title}}
    </h3>
    <p *ngIf="description" id="ptx">
        {{description}}
    </p>
    <ul>
        <li *ngFor="let item of items">{{item}}</li>
    </ul>  
</div>
