import { removeSummaryDuplicates } from '@angular/compiler';
import { Component, Input, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Product } from 'src/app/models/product';
import { ProductType } from 'src/app/models/product-type';
import { BasicInformationService } from 'src/app/services/basic-information.service';
import { ProductService } from 'src/app/services/product.service';
import { FirebaseConverter } from 'src/app/tools/firebase-converter';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'bm-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {
  productId: string;
  product: Product = new Product(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null,null,null,null);
  productFreeDelivery: boolean;
  productCode: string;
  displayPromotion: boolean = false;
  finalPrice: number;
  popularProducts: Product[] = [];
  similarProductsIds: number[] = [];
  showSimilar: boolean = true;
  loading:boolean=true;
  types:number[]=[12,24,32,48];
  whatsappLink="";
  firebaseConverter: FirebaseConverter = new FirebaseConverter();
  constructor(private _Activatedroute: ActivatedRoute, private productService: ProductService,private titleService: Title,private basicInformationService: BasicInformationService, private analytics: AngularFireAnalytics,private meta: Meta) {
    this._Activatedroute.params.subscribe(val => {
      this.productCode = val.productCode;
      this.populateProductInformation();
    });
  }
  prepareMeta(){
    this.meta.removeTag("name='description'");
    this.meta.removeTag("name='image'");
    this.meta.removeTag("name='og:description'");
    this.meta.removeTag("name='og:image'");
    this.meta.removeTag("name='twitter:image'");
    this.meta.removeTag("name='twitter:description'");

    this.meta.addTags([
    {
      name: 'description',
      content: this.product.$name
    },{
      name: 'image',
      content: this.product.$image
    },{
      name: 'og:description',
      content: this.product.$name
    },{
      name: 'og:image',
      content: this.product.$image
    },{
      name: 'twitter:image',
      content: this.product.$image
    },{
      name: 'twitter:description',
      content: this.product.$name
    }]);
  }
  ngOnInit(): void {
    if (this.productCode == this._Activatedroute.snapshot.paramMap.get("productCode")) {
      return;
    }
    this.productCode = this._Activatedroute.snapshot.paramMap.get("productCode");
    this.populateProductInformation();
  }
   piecesSelected(productType:ProductType){
     this.product.$pieces=productType.$pieces;
     this.product.$size=productType.$size;
     this.product.$price=productType.$price;
     if(productType.$image!=null){
       this.product.$image=productType.$image;
     }
     this.prepareWhatsappLink();
   } 
  
  populateProductInformation() {
    var parent = this
    this.popularProducts = [];
    console.log(`Populating product ${this.productCode}`)
    var promise = this.productService.getProductByProductCode(this.productCode);
    promise.then(function (response) {
      parent.product = parent.firebaseConverter.parseFBObjectToProduct(response);
      console.log(parent.product)
      parent.productFreeDelivery=parent.product.$freeDelivery;
      parent.titleService.setTitle(parent.product.$name + ' - Pasterlería Las Buenas Migas'); 
      parent.populateSimilarProducts(parent.product.$similarProducts);
      if (parent.product.$similarProducts.toString() == "") {
        parent.showSimilar = false;
      }
      parent.prepareWhatsappLink();
      parent.loading=false;
      parent.sendDataToAnalytics();
      //Uncomment the next line to update meta
      //parent.prepareMeta();
    }).catch(function (error) {
      console.log(error)
    })
  }
  sendDataToAnalytics(){
    this.analytics.logEvent('view_item', { items: [
      { 'item_id':this.productCode, 
      item_name: this.product.$name,
      currency: "BOB",
      price: this.product.$price
    }]
    });
  }
  prepareWhatsappLink(){
    var defaultLink=this.whatsappLink=`${this.basicInformationService.getInformation().$whatsapp}?text=Hola! Quisiera hacer un pedido de ${this.product.$name} de Bs. ${this.product.$price}` ;
    if(this.product.$pieces==""){
      this.whatsappLink=`${defaultLink}`;  
    }else{
      this.whatsappLink=`${defaultLink} (${this.product.$pieces} porciones)` ;    
    }
    
  }
  populateSimilarProducts(productCodes) {
    var parent = this;
    for (var productCodeItem of productCodes) {
      var promise = this.productService.getProductByProductCode(productCodeItem);
      promise.then(function (response) {
        parent.popularProducts.push(parent.firebaseConverter.parseFBObjectToProduct(response));
      }).catch(function (error) {
        console.log(error)
      })
    }
  }

}
