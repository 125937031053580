import { Product } from "../models/product";
import { ProductType } from "../models/product-type";

export class FirebaseConverter {

  constructor() {

  }

  parseFBObjectToProductArray(firebaseObject) {
    var productResult = [];

    for (var productDocument of firebaseObject.data) {
      if (productDocument.document != null) {
        var fields = productDocument.document.fields;
        var productId = this.firebaseDocumentNameToId(productDocument.document.name);
        var productTypes=this.parseTypesToProductTypes(fields);
        var price=this.handlePrice(fields.price);
        var product = new Product(fields.name.stringValue, price, Number(fields.promotionPrice.integerValue), fields.image.stringValue, null, fields.isVisible.booleanValue, null, productId, fields.code.stringValue, fields.description.stringValue, fields.pieces.stringValue, fields.weight.stringValue, fields.size.stringValue, fields.freeDelivery.booleanValue, this.parseFBArrayToArray(fields.similarProducts.arrayValue.values), productTypes,fields.onDemand?.booleanValue,fields.note?.booleanValue );
        productResult.push(product)
      }

    }
    return productResult;
  }
  parseFBArrayToArray(firebaseArray) {
    var arrayResult = [];
    if (firebaseArray == undefined) {
      return arrayResult;
    }
    for (var arrayItem of firebaseArray) {
      arrayResult.push(arrayItem.stringValue)
    }
    return arrayResult;
  }
  


  parseTypesToProductTypes(fields){
    var productTypes = [];
    if (fields.types != undefined) {
      var types = fields.types.arrayValue.values;
      for (let type of types) {
        var typeFields = type.mapValue.fields
        var pieces=typeFields.pieces.stringValue
        var price=this.handlePrice(typeFields.price);
        var image=(typeFields.image == undefined) ? null : typeFields.image.stringValue;
        var size=typeFields.size.stringValue
        var productTypeItem = new ProductType(pieces, price, image, size);
        productTypes.push(productTypeItem);
      }
    }
    return productTypes;

  }
  
  parseFBObjectToProduct(firebaseObject) {
    var productResult;
    var data = firebaseObject.data[0];
    var fields = data.document.fields;
    var productId = this.firebaseDocumentNameToId(data.document.name);
    var price=this.handlePrice(fields.price);
    var productTypes = this.parseTypesToProductTypes(fields);
    productResult = new Product(fields.name.stringValue, price, Number(fields.promotionPrice.integerValue), fields.image.stringValue, null, fields.isVisible.booleanValue, null, productId, fields.code.stringValue, fields.description.stringValue, fields.pieces.stringValue, fields.weight.stringValue, fields.size.stringValue, fields.freeDelivery.booleanValue, this.parseFBArrayToArray(fields.similarProducts.arrayValue.values), productTypes,fields.onDemand?.booleanValue,fields.note?.stringValue);
    
    return productResult;
  }

  firebaseDocumentNameToId(documentName){
    var splitName = documentName.split("/");
    var id = splitName[6];
    return id;
  }
  handlePrice(priceField):number{
    var price;
    if(priceField.integerValue == undefined){
      price=priceField.doubleValue;
    } 
    else{
      price=priceField.integerValue;
    }
    return price;
  }
}
