<div id="product">
    <img id="lemonade" src="{{image}}">
    <h3 id="title">
        {{title}}
    </h3>
     <p *ngIf="displayPromotion" id="price">
        Bs.{{price}}
    </p> 
     <p id="price2">
        Bs.{{finalPrice}}
    </p> 
     <p *ngIf="onDemand" id="ondemand"  >
            <strong>SOLO A PEDIDO</strong> 
         </p>
</div>