<div id="images">
    <img routerLink="" src="/assets/images/LOGO_BUENAS_MIGAS.svg" alt="">
</div>
<div id="footer">

    <p id="txt">
        © {{currentYear}}. Pasterleria Buenas Migas. Todos los derechos reservados
    </p>
    <div id="comp">
        <bm-icon-button image="/assets/images/whatsapp-orange.svg" text={{basicInformation.$cellphone}} url={{basicInformation.$whatsapp}}>
        </bm-icon-button>
        <bm-icon-button image="/assets/images/phone.svg" text={{basicInformation.$phone}} url="tel:6436391">
        </bm-icon-button>
        <bm-icon-button image="/assets/images/map-marker.svg" text={{basicInformation.$address}}
            url={{basicInformation.$location}}></bm-icon-button>
        <bm-icon-button image="/assets/images/facebook(1).svg" text="{{basicInformation.$facebook}}"
            url={{basicInformation.$facebookUrl}}></bm-icon-button>
            <bm-icon-button image="/assets/images/instagram-orange.svg" text="{{basicInformation.$instagram}}"
            url={{basicInformation.$instagramUrl}}></bm-icon-button>

    </div>
</div>