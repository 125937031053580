export class BasicInformation{
    private address:string;
    private location:string;
    private country:string;
    private phone:string;
    private cellphone:string;
    private whatsapp:string;
    private facebook:string;
    private facebookUrl:string;
    private instagram:string;
    private instagramUrl:string;
    private messengerUrl:string;



	constructor($address: string, $location: string, $country: string, $phone: string, $cellphone: string, $whatsapp: string, $facebook: string, $facebookUrl: string, $instagram: string, $instagramUrl: string, $messengerUrl: string) {
		this.address = $address;
		this.location = $location;
		this.country = $country;
		this.phone = $phone;
		this.cellphone = $cellphone;
		this.whatsapp = $whatsapp;
		this.facebook = $facebook;
		this.facebookUrl = $facebookUrl;
		this.instagram = $instagram;
		this.instagramUrl = $instagramUrl;
		this.messengerUrl = $messengerUrl;
	}

    /**
     * Getter $address
     * @return {string}
     */
	public get $address(): string {
		return this.address;
	}

    /**
     * Getter $location
     * @return {string}
     */
	public get $location(): string {
		return this.location;
	}

    /**
     * Getter $country
     * @return {string}
     */
	public get $country(): string {
		return this.country;
	}

    /**
     * Getter $phone
     * @return {string}
     */
	public get $phone(): string {
		return this.phone;
	}

    /**
     * Getter $cellphone
     * @return {string}
     */
	public get $cellphone(): string {
		return this.cellphone;
	}

    /**
     * Getter $whatsapp
     * @return {string}
     */
	public get $whatsapp(): string {
		return this.whatsapp;
	}

    /**
     * Getter $facebook
     * @return {string}
     */
	public get $facebook(): string {
		return this.facebook;
	}

    /**
     * Getter $facebookUrl
     * @return {string}
     */
	public get $facebookUrl(): string {
		return this.facebookUrl;
	}

    /**
     * Getter $instagram
     * @return {string}
     */
	public get $instagram(): string {
		return this.instagram;
	}

    /**
     * Getter $instagramUrl
     * @return {string}
     */
	public get $instagramUrl(): string {
		return this.instagramUrl;
	}

    /**
     * Getter $messengerUrl
     * @return {string}
     */
	public get $messengerUrl(): string {
		return this.messengerUrl;
	}

    /**
     * Setter $address
     * @param {string} value
     */
	public set $address(value: string) {
		this.address = value;
	}

    /**
     * Setter $location
     * @param {string} value
     */
	public set $location(value: string) {
		this.location = value;
	}

    /**
     * Setter $country
     * @param {string} value
     */
	public set $country(value: string) {
		this.country = value;
	}

    /**
     * Setter $phone
     * @param {string} value
     */
	public set $phone(value: string) {
		this.phone = value;
	}

    /**
     * Setter $cellphone
     * @param {string} value
     */
	public set $cellphone(value: string) {
		this.cellphone = value;
	}

    /**
     * Setter $whatsapp
     * @param {string} value
     */
	public set $whatsapp(value: string) {
		this.whatsapp = value;
	}

    /**
     * Setter $facebook
     * @param {string} value
     */
	public set $facebook(value: string) {
		this.facebook = value;
	}

    /**
     * Setter $facebookUrl
     * @param {string} value
     */
	public set $facebookUrl(value: string) {
		this.facebookUrl = value;
	}

    /**
     * Setter $instagram
     * @param {string} value
     */
	public set $instagram(value: string) {
		this.instagram = value;
	}

    /**
     * Setter $instagramUrl
     * @param {string} value
     */
	public set $instagramUrl(value: string) {
		this.instagramUrl = value;
	}

    /**
     * Setter $messengerUrl
     * @param {string} value
     */
	public set $messengerUrl(value: string) {
		this.messengerUrl = value;
	}
	

}