import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'bm-about-us-card',
  templateUrl: './about-us-card.component.html',
  styleUrls: ['./about-us-card.component.scss']
})
export class AboutUsCardComponent implements OnInit {

  constructor() { }
@Input() title:string;
@Input() description:string;
@Input() items:string[];
  

  ngOnInit(): void {
  }

}
