export class ProductType{
    private pieces:string;
    private price:number;
    private image:string;
    private size:string;
	 

	constructor($pieces: string, $price: number, $image: string, $size: string) {
		this.pieces = $pieces;
		this.price = $price;
		this.image = $image;
		this.size = $size;
	}

    /**
     * Getter $pieces
     * @return {string}
     */
	public get $pieces(): string {
		return this.pieces;
	}

    /**
     * Setter $pieces
     * @param {string} value
     */
	public set $pieces(value: string) {
		this.pieces = value;
	}

    /**
     * Getter $price
     * @return {number}
     */
	public get $price(): number {
		return this.price;
	}

    /**
     * Setter $price
     * @param {number} value
     */
	public set $price(value: number) {
		this.price = value;
	}

    /**
     * Getter $image
     * @return {string}
     */
	public get $image(): string {
		return this.image;
	}

    /**
     * Setter $image
     * @param {string} value
     */
	public set $image(value: string) {
		this.image = value;
	}

    /**
     * Getter $size
     * @return {string}
     */
	public get $size(): string {
		return this.size;
	}

    /**
     * Setter $size
     * @param {string} value
     */
	public set $size(value: string) {
		this.size = value;
	}
    
    
}
