<h2 id="title">{{title}}</h2>
<div id="services">
    <bm-service-card image="/assets/images/matrimonio1.jpeg" title="Tortas de Matrimonio"
        text="Porque se acerca el día tan esperado y sabemos lo importante que es para ti. Buenas Migas puede preparar la torta de tus sueños, con un diseño personalizado variedad de sabores para que tú y todos los seres que amas pasen un día inolvidable."
        url="/catalogo/tortas" externalLink="https://wa.me/59170329077?text=Hola! Quisiera obtener más información de las Tortas de Matrimonio">
    </bm-service-card>
    <bm-service-card image="/assets/images/personalizado2.JPG" title="Diseños Personalizados" 
        externalLink="https://wa.me/59170329077?text=Hola! Quisiera un diseño personalizado"
        text="Tienes un diseño en mente? Nosotros lo hacemos realidad."
        url="/catalogo/queques">
    </bm-service-card>
    <bm-service-card image="/assets/images/especial.jpg" title="Pedidos Especiales"
        text="Deseas que tu torta tenga un decorado o relleno diferente, nosotros te ayudamos con ello."
        externalLink="https://wa.me/59170329077?text=Hola! Quisiera hacer un pedido especial"
        url="/catalogo/tortas"></bm-service-card>

</div>