<div *ngIf="loading"  id="loa"> 
   
    <img id="rotate" src="/assets/images/loading.svg">
</div>
<div id="di" *ngIf="!loading">
    <div id="imgF">
        <img id="img" src="{{product.$image}}" >
    </div>
    <div>
        <h2>
            {{product.$name}}
        </h2>
        <h3 *ngIf="displayPromotion" id="oldPrice">
            Bs. {{product.$promotionPrice}}
          </h3>
        <h3 id="colorLetra">
          Bs. {{product.$finalPrice}}
        </h3>
        <p id="li">
           {{product.$description}}
        </p>
        <p id="note" *ngIf="product.$note" >
            <strong>Nota: </strong> {{product.$note}}
         </p>
        <p >
            <strong>Porciones: </strong> {{product.$pieces}}
        </p>
        <div >
            <button *ngFor="let type of product.$types" (click)="piecesSelected(type)" class="box">
               <p class="boxtext">{{type.$pieces}}</p> 
            </button>
        </div>
        <p *ngIf="product.$size">
           <strong>Tamaño:  </strong>  {{product.$size}}
        </p>
        <p *ngIf="product.$onDemand">
            <strong>SOLO A PEDIDO</strong>
         </p>
        <p *ngIf="productFreeDelivery" class="free" >
            <strong>Envío Gratuito</strong> 
        </p>
        <p *ngIf="!productFreeDelivery" class="free">
            <strong>Envío Bs. 10</strong> 
        </p>
        <p id="delivery">
            En el área urbana de Sucre
        </p>
        <bm-button  label="Reserva ya!" externalLink="{{whatsappLink}}"  ></bm-button>
    </div>
</div>
<bm-category *ngIf="showSimilar && !loading" categoryName="PRODUCTOS SIMILARES" [products]="popularProducts"></bm-category>