<div class="jum"  style="background-image: url({{background}});">
    <div class={{alignment}}>
        <h1 id="text" class="animation">
            {{title}}
        </h1>
        <h3 class="animation">
            {{description}}
        </h3>

        <bm-button class="animation" label={{buttonLabel}} path={{buttonPath}} ></bm-button>
    </div>
</div>