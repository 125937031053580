import { Component, Input, OnInit } from '@angular/core';
import { BasicInformation } from 'src/app/models/basic-information';
import { BasicInformationService } from 'src/app/services/basic-information.service';


@Component({
  selector: 'bm-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
  basicInformation:BasicInformation;
  @Input() whatsappurl: string;
  @Input() messengerurl: string;
  whatsappVisible:boolean=false;
  messengerVisible:boolean=false;
  closeVisible:boolean=false;
  openVisible:boolean=true;

  onButtonType(type:string){
    console.log(type)
    if(type=="close"){
      this.whatsappVisible=false;
      this.messengerVisible=false;
      this.closeVisible=false;
      this.openVisible=true;
     }
     if(type=="open"){
       this.whatsappVisible=true;
       this.messengerVisible=true;
       this.closeVisible=true;
       this.openVisible=false;
      
     }
  }
  constructor(private basicInformationService:BasicInformationService) {}

  ngOnInit(): void {
    this.basicInformation=this.basicInformationService.getInformation();
  }
  
}
