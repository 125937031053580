import { Component, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Product } from 'src/app/models/product';
import { ProductService } from 'src/app/services/product.service';
import { FirebaseConverter } from 'src/app/tools/firebase-converter';
@Component({
  selector: 'bm-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss']
})
export class CatalogComponent implements OnInit {
  category: string;
  products:Product[] = [];
  searchText: string;
  loading:boolean=false;
 firebaseConverter:FirebaseConverter=new FirebaseConverter();

  constructor(private _Activatedroute: ActivatedRoute, private productService: ProductService,private titleService: Title, private analytics: AngularFireAnalytics, private meta: Meta) {
    this.category = this._Activatedroute.snapshot.paramMap.get("category");
    this._Activatedroute.params.subscribe(val => {
      this.products=[];
      this.loading=true;
      this.populateListInformation(val.category);
      this.category = val.category;
    });
  }

  populateListInformation(category: String){
    var parent = this;
    this.productService.getProductsByCategory(category).then(function (response) {
      parent.products = parent.firebaseConverter.parseFBObjectToProductArray(response);
      parent.loading=false;
      parent.sendDataToAnalytics();
      //Uncomment next line to handle meta
      //parent.prepareMeta();
    }).catch(function (error) {
      console.log(error)
      parent.loading=false;
    })
  }

  prepareMeta(){
    this.meta.removeTag("name='description'");
    this.meta.removeTag("name='image'");
    this.meta.removeTag("name='og:description'");
    this.meta.removeTag("name='og:image'");
    this.meta.removeTag("name='twitter:image'");
    this.meta.removeTag("name='twitter:description'");

    this.meta.addTags([
    {
      name: 'description',
      content: this.category.toUpperCase()
    },{
      name: 'og:description',
      content: this.category.toUpperCase()
    },
    {
      name: 'twitter:description',
      content: this.category.toUpperCase()
    }]);
  }
  sendDataToAnalytics(){
    const itemsArray = this.products.map((val: Product)=>{
      return {
        item_id: val.$code,
        item_name: val.$name,
        currency: "BOB",
        price: val.$price
      }

    })

    const itemsList = {
      item_list_id: this.category,
      item_list_name: this.category,
      items: itemsArray
    }

    this.analytics.logEvent('view_item_list', itemsList);
  }

  capitalize(word) {
    return word[0].toUpperCase() + word.slice(1);
  }
  

  ngOnInit(): void {
    if (this.category == this._Activatedroute.snapshot.paramMap.get("category")) {
      return;
    }
    this.loading=true;
    this.titleService.setTitle(this.capitalize(this.category) + ' - Pasterlería Las Buenas Migas')
    this.populateListInformation(this.category);
    
  }

  searchProduct() {
    var parent = this
    this.loading=true;
    this.category="";

    this.analytics.logEvent('search', { search_term: this.searchText});

    if(this.searchText==""){
      this.searchText="TODOS";
    }

    this.productService.getProductsByText(this.searchText).then(function (response) {
      parent.products = parent.firebaseConverter.parseFBObjectToProductArray(response);

      if(parent.products.length==1){
        parent.category= parent.products.length+ " resultado";
      }else{
        parent.category= parent.products.length+ " resultados";
      }
    parent.loading=false;
      

    }).catch(function (error) {
      console.log(error)
      parent.loading=false;
    })
  }

  onKey(event) {
    this.searchText = event.target.value;
    if (event.keyCode == 13) {
      this.searchProduct()
    }
  }

}
