import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Jumbo } from 'src/app/models/jumbo';
import { Product } from 'src/app/models/product';
import { BasicInformationService } from 'src/app/services/basic-information.service';
import { ProductService } from 'src/app/services/product.service';
import { FirebaseConverter } from 'src/app/tools/firebase-converter';

@Component({
  selector: 'bm-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  popularProducts: Product[] = [];
  newProducts: Product[] = [];
  cover: Jumbo = new Jumbo();
  featured: Jumbo = new Jumbo();
  jumboList;
 firebaseConverter:FirebaseConverter=new FirebaseConverter();
  constructor(private productService: ProductService, private basicInformationService: BasicInformationService,private titleService: Title) {

  }

  ngOnInit(): void {
    this.prepareCover();
    this.setProducts();
    this.prepareFeatured();
    this.titleService.setTitle("Pasterlería Las Buenas Migas");
  }

  prepareCover() {
    var parent = this;
    var promise = this.basicInformationService.getJumboByType("cover");
    promise.then(function (response) {
      parent.cover = parent.parseFBObjectToJumbo(response);
    }).catch(function (error) {
      console.log(error)
    })
  }

  prepareFeatured() {
    var parent = this;
    var promise = this.basicInformationService.getJumboByType("featured");
    promise.then(function (response) {
      parent.featured = parent.parseFBObjectToJumbo(response);
    }).catch(function (error) {
      console.log(error)
    })
  }

  parseFBObjectToJumbo(firebaseObject) {
    var jumboResult;
    var fields = firebaseObject.data[0].document.fields;
    jumboResult = new Jumbo();
    jumboResult.$title = fields.title.stringValue;
    jumboResult.$description = fields.description.stringValue;
    jumboResult.$buttonLabel = fields.buttonLabel.stringValue;
    jumboResult.$background = fields.background.stringValue;
    jumboResult.$path = fields.path.stringValue;
    jumboResult.$type = fields.type.stringValue;
    return jumboResult;
  }


  setProducts() {

    var parent = this
    this.productService.getProductsByCategory('popular').then(function (response) {
      parent.popularProducts = parent.firebaseConverter.parseFBObjectToProductArray(response);
    }).catch(function (error) {
      console.log(error)
    })

    this.productService.getProductsByCategory('nuevos').then(function (response) {
      parent.newProducts = parent.firebaseConverter.parseFBObjectToProductArray(response);
    }).catch(function (error) {
      console.log(error)
    })

  }
  

}
