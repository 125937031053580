import { Component, OnInit,Input} from '@angular/core';

@Component({
  selector: 'bm-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.scss']
})
export class OurServicesComponent implements OnInit {

  constructor() { }
 
  @Input() title:string;
 

  ngOnInit(): void {
  }

}
