import { Component, OnInit } from '@angular/core';
import { BasicInformation } from 'src/app/models/basic-information';
import { BasicInformationService } from 'src/app/services/basic-information.service';

@Component({
  selector: 'bm-enterprise-info',
  templateUrl: './enterprise-info.component.html',
  styleUrls: ['./enterprise-info.component.scss']
})
export class EnterpriseInfoComponent implements OnInit {

  basicInformation:BasicInformation;

  constructor(private basicInformationService:BasicInformationService) { 

  }
  openUrl(url){
    window.open(url);
  }
  ngOnInit(): void {
    this.basicInformation=this.basicInformationService.getInformation();
  }

}
