import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'bm-jumbotron',
  templateUrl: './jumbotron.component.html',
  styleUrls: ['./jumbotron.component.scss']
})
export class JumbotronComponent implements OnInit {

  constructor() { }
  @Input() title: string;
  @Input() description: string;
  @Input() background: string;
  @Input() buttonLabel: string;
  @Input() buttonUrl: string;
  @Input() alignment: string = 'jum';
  @Input() buttonPath: string;
  ngOnInit(): void {
  }

}
