import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'bm-service-card',
  templateUrl: './service-card.component.html',
  styleUrls: ['./service-card.component.scss']
})
export class ServiceCardComponent implements OnInit {

  constructor() { }
@Input() image:string;
@Input() title:string;
@Input() text:string;
@Input() url:string;
@Input() externalLink:string;
onClick(){
  window.open(this.externalLink);
}
  ngOnInit(): void {
  }

}
 