import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'bm-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
@Input() items:object[];
  constructor() { }

  ngOnInit(): void {
  }

}
