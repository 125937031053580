<h1 id="til">
    {{title}}
</h1>
<div id="pr">

    <div class="column">
        <bm-feature-card title="Calidad de Productos"
            description="Garantizamos gran calidad en nuestras tortas ya que son preparadas con los mejores ingredientes"
            icon="/assets/images/cake.svg"></bm-feature-card>
        <bm-feature-card title="Envío"
            description="Todos los pedidos en el área urbana de Sucre hasta la puerta de tu casa"
            icon="/assets/images/truck-delivery-outline.svg"></bm-feature-card>
    </div>
    <div id="img-cont">
        <img id="images" src="/assets/images/calum-lewis-rkT_TG5NKF8-unsplash.jpg">
    </div>
    <div class="column">
        <bm-feature-card title="Decoración Personalizada"
            description="Quieres que tu torta tenga un mensaje especial? no hay problema, agregamos los detalles que pidas"
            icon="/assets/images/robot-love-outline.svg"></bm-feature-card>
        <bm-feature-card title="Pagos"
            description=" Realiza tus pagos con una simple transferencia o en efectivo"
            icon="/assets/images/credit-card-marker-outline.svg"></bm-feature-card>
    </div>
</div>