import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'bm-about-usabout-us',
  templateUrl: './about-usabout-us.component.html',
  styleUrls: ['./about-usabout-us.component.scss']
})
export class AboutUsaboutUsComponent implements OnInit {

  constructor() { }
  @Input() text: string;
  @Output() pressed = new EventEmitter<string>();
  
  showDescription() {
    console.log(this.text)
    this.pressed.emit(this.text);
  }

  ngOnInit(): void {
  }

}
