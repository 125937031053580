import { Component, Input, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'bm-circle-button',
  templateUrl: './circle-button.component.html',
  styleUrls: ['./circle-button.component.scss']
})
export class CircleButtonComponent implements OnInit {


  @Input() type:string;
  @Input() icon: string;
  @Input() color: string = "orange";
  @Input() url: string = "";

  @Output() buttonType = new EventEmitter<string>();

  classVisible: string = "visibleButton";

  onClick() {
    if (this.url != "") {
      window.open(this.url);
    }
    this.buttonType.emit(this.type);

    
  }
  constructor() {


  }

  ngOnInit(): void {
    
  }

}
