import { Component, OnInit } from '@angular/core';
import { BasicInformation } from 'src/app/models/basic-information';
import { BasicInformationService } from 'src/app/services/basic-information.service';


@Component({
  selector: 'bm-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  basicInformation:BasicInformation;
  currentYear=new Date().getFullYear();
  constructor(private basicInformationService:BasicInformationService) { 

  }

  ngOnInit(): void {
    this.basicInformation=this.basicInformationService.getInformation();
  }

}
