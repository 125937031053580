import { ProductType } from "./product-type";

 export class Product{

    private name:string;
    private price:number;
    private promotionPrice:number;
    private image:string;
    private categories:string[];
    private isVisible:boolean;
    private tags:string[];
    private id:number;
    private code:string;
    private description:string;
    private pieces:string;
    private weight:string;
    private size:string;
    private freeDelivery:boolean;
    private similarProducts:string[];
    private types:ProductType[];
    private onDemand:boolean;
    private note:string;
    

	constructor($name: string, $price: number, $promotionPrice: number, $image: string, $categories: string[], $isVisible: boolean, $tags: string[], $id: number, $code: string, $description: string, $pieces: string, $weight: string, $size: string, $freeDelivery: boolean, $similarProducts: string[], $types:ProductType[], $onDemand:boolean, $note:string) {
		this.name = $name;
		this.price = $price;
		this.promotionPrice = $promotionPrice;
        if($image==""){
            this.image="/assets/images/defaultProductImage.svg";
        }else{
          this.image=$image;
        }
		this.categories = $categories;
		this.isVisible = $isVisible;
		this.tags = $tags;
		this.id = $id;
		this.code = $code;
		this.description = $description;
		this.pieces = $pieces;
		this.weight = $weight;
		this.size = $size;
		this.freeDelivery = $freeDelivery;
		this.similarProducts = $similarProducts;
        this.types= $types;
        this.onDemand=$onDemand;
        this.note =$note;
	}


    /**
     * Getter $name
     * @return {string}
     */
	public get $name(): string {
		return this.name;
	}

    /**
     * Getter $price
     * @return {number}
     */
	public get $price(): number {
		return this.price;
	}

    /**
     * Getter $promotionPrice
     * @return {number}
     */
	public get $promotionPrice(): number {
		return this.promotionPrice;
	}

    /**
     * Getter $image
     * @return {string}
     */
	public get $image(): string {
		return this.image;
	}

    /**
     * Getter $categories
     * @return {string[]}
     */
	public get $categories(): string[] {
		return this.categories;
	}

    /**
     * Getter $isVisible
     * @return {boolean}
     */
	public get $isVisible(): boolean {
		return this.isVisible;
	}

    /**
     * Getter $tags
     * @return {string[]}
     */
	public get $tags(): string[] {
		return this.tags;
	}

    /**
     * Getter $id
     * @return {number}
     */
	public get $id(): number {
		return this.id;
	}

    /**
     * Getter $code
     * @return {string}
     */
	public get $code(): string {
		return this.code;
	}

    /**
     * Getter $description
     * @return {string}
     */
	public get $description(): string {
		return this.description;
	}

    /**
     * Getter $pieces
     * @return {string}
     */
	public get $pieces(): string {
		return this.pieces;
	}

    /**
     * Getter $weight
     * @return {string}
     */
	public get $weight(): string {
		return this.weight;
	}

    /**
     * Getter $size
     * @return {string}
     */
	public get $size(): string {
		return this.size;
	}

    /**
     * Getter $freeDelivery
     * @return {boolean}
     */
	public get $freeDelivery(): boolean {
		return this.freeDelivery;
	}

    /**
     * Getter $similarProducts
     * @return {string[]}
     */
	public get $similarProducts(): string[] {
		return this.similarProducts;
	}
     /**
     * Getter $onDemand
     * @return {boolean}
     */
	public get $onDemand():boolean {
		return this.onDemand;
	}
    /**
     * Getter $note
     * @return {string}
     */
    public get $note():string {
        return this.note;
    }
    /**
     * Setter $name
     * @param {string} value
     */
	public set $name(value: string) {
		this.name = value;
	}

    /**
     * Setter $price
     * @param {number} value
     */
	public set $price(value: number) {
		this.price = value;
	}

    /**
     * Setter $promotionPrice
     * @param {number} value
     */
	public set $promotionPrice(value: number) {
		this.promotionPrice = value;
	}

    /**
     * Setter $image
     * @param {string} value
     */
	public set $image(value: string) {
		this.image = value;
	}

    /**
     * Setter $categories
     * @param {string[]} value
     */
	public set $categories(value: string[]) {
		this.categories = value;
	}

    /**
     * Setter $isVisible
     * @param {boolean} value
     */
	public set $isVisible(value: boolean) {
		this.isVisible = value;
	}

    /**
     * Setter $tags
     * @param {string[]} value
     */
	public set $tags(value: string[]) {
		this.tags = value;
	}

    /**
     * Setter $id
     * @param {number} value
     */
	public set $id(value: number) {
		this.id = value;
	}

    /**
     * Setter $code
     * @param {string} value
     */
	public set $code(value: string) {
		this.code = value;
	}

    /**
     * Setter $description
     * @param {string} value
     */
	public set $description(value: string) {
		this.description = value;
	}

    /**
     * Setter $pieces
     * @param {string} value
     */
	public set $pieces(value: string) {
		this.pieces = value;
	}

    /**
     * Setter $weight
     * @param {string} value
     */
	public set $weight(value: string) {
		this.weight = value;
	}

    /**
     * Setter $size
     * @param {string} value
     */
	public set $size(value: string) {
		this.size = value;
	}

    /**
     * Setter $freeDelivery
     * @param {boolean} value
     */
	public set $freeDelivery(value: boolean) {
		this.freeDelivery = value;
	}
     /**
     * Setter $onDemand
     * @param {boolean} value
     */
	public set $onDemand(value: boolean) {
		this.onDemand = value;
	}
     /**
     * Setter $note
     * @param {string} value
     */
	public set $note(value: string) {
		this.note = value;
	}

    /**
     * Setter $similarProducts
     * @param {string[]} value
     */
	public set $similarProducts(value: string[]) {
		this.similarProducts = value;
	}

     /**
      * Setter $displayPromotion
      *      */

     public get $displayPromotion(): boolean {
         if ((this.promotionPrice !== null) && (this.promotionPrice !==0)) {
             return true;
         }
         return false
     }

     public get $finalPrice(): number {
         if (this.$displayPromotion == true) {
             return this.promotionPrice;
         } else {
             return this.price;
         }
     }

    /**
     * Getter $types
     * @return {ProductType[]}
     */
	public get $types(): ProductType[] {
		return this.types;
	}

    /**
     * Setter $types
     * @param {ProductType[]} value
     */
	public set $types(value: ProductType[]) {
		this.types = value;
	}
	
    
}
