import { Component, OnInit,Input} from '@angular/core';

@Component({
  selector: 'bm-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent implements OnInit {

  constructor() { }
  @Input() text:string;
  @Input() url:string;
  @Input() image:string;
  onClick(){
    window.open(this.url);
  }
  ngOnInit(): void {
  }

}
