import { Injectable } from '@angular/core';
import axios from 'axios';
import{Product} from './../models/product'
declare var require: any

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor() { }

  getProductsByCategory(category):any{
    return axios.post('https://firestore.googleapis.com/v1/projects/buenas-migas-c15ad/databases/(default)/documents:runQuery',
    {
      "structuredQuery": {
          "from": [
              {
                  "collectionId": "products",
                  "allDescendants": true
              }
          ],
          "where": {
              "fieldFilter": {
                  "field": {
                      "fieldPath": "categories"
                  },
                  "op": "ARRAY_CONTAINS",
                  "value": {
                      "stringValue": category
                  }
              }
          }
      }
  });
}
  getProductsByText(text:string) {
    return axios.post('https://firestore.googleapis.com/v1/projects/buenas-migas-c15ad/databases/(default)/documents:runQuery',
    {
      "structuredQuery": {
          "from": [
              {
                  "collectionId": "products",
                  "allDescendants": true
              }
          ],
          "where": {
              "fieldFilter": {
                  "field": {
                      "fieldPath": "tags"
                  },
                  "op": "ARRAY_CONTAINS",
                  "value": {
                      "stringValue": text.toUpperCase()
                  }
              }
          }
      }
  });

  }
  

  getProductByProductCode(productCode):any {
    return axios.post('https://firestore.googleapis.com/v1/projects/buenas-migas-c15ad/databases/(default)/documents:runQuery',
    {
      "structuredQuery": {
          "from": [
              {
                  "collectionId": "products",
                  "allDescendants": true
              }
          ],
          "where": {
              "fieldFilter": {
                  "field": {
                      "fieldPath": "code"
                  },
                  "op": "EQUAL",
                  "value": {
                      "stringValue": productCode
                  }
              }
          }
      }
  });
    
    

  }

}

