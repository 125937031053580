import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { ProductService } from './services/product.service';

@Component({
  selector: 'bm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  menuItems = [
    {
      text: "Tortas",
      link: "/catalogo/tortas"
    },
    {
      text: "Queques",
      link: "/catalogo/queques"
    },
    {
      text: "Pies",
      link: "/catalogo/pies"
    },
    {
      text: "Brazos",
      link: "/catalogo/brazos"
    },
    {
      text: "Masitas",
      link: "/catalogo/masitas"
    }
  ]
  constructor(private router: Router, private titleService: Title, private meta: Meta) { }

  prepareMeta(){
    this.meta.removeTag("name='description'");
    this.meta.removeTag("name='image'");
    this.meta.removeTag("name='og:description'");
    this.meta.removeTag("name='og:image'");
    this.meta.removeTag("name='twitter:image'");
    this.meta.removeTag("name='twitter:description'");

    this.meta.addTags([
    {
      name: 'description',
      content: "Ofrecemos las mejores tortas y masitas"
    },{
      name: 'image',
      content: "https://firebasestorage.googleapis.com/v0/b/buenas-migas-c15ad.appspot.com/o/products%2FLOGO_BUENAS_MIGAS.ico?alt=media&token=02000faa-0050-48bb-829f-6a3adda48855"
    },{
      name: 'og:description',
      content: "Ofrecemos las mejores tortas y masitas"
    },{
      name: 'og:image',
      content: "https://firebasestorage.googleapis.com/v0/b/buenas-migas-c15ad.appspot.com/o/products%2FLOGO_BUENAS_MIGAS.ico?alt=media&token=02000faa-0050-48bb-829f-6a3adda48855"
    },{
      name: 'twitter:image',
      content: "https://firebasestorage.googleapis.com/v0/b/buenas-migas-c15ad.appspot.com/o/products%2FLOGO_BUENAS_MIGAS.ico?alt=media&token=02000faa-0050-48bb-829f-6a3adda48855"
    },{
      name: 'twitter:description',
      content: "Ofrecemos las mejores tortas y masitas"
    }]);
  }
  ngOnInit() {
    this.titleService.setTitle("Pasterlería Las Buenas Migas")
    this.router.events.subscribe((evt) => {

      //Uncomment the next line to handle meta on root
      /*
      if(location.pathname === '/'){
        this.prepareMeta();
      }*/
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }

}



