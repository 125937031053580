// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig:{
    apiKey: "AIzaSyBShgAeTVZxvttSD59r42f2Wp64W1vFSZo",
    authDomain: "buenas-migas-c15ad.firebaseapp.com",
    databaseURL: "https://buenas-migas-c15ad-default-rtdb.firebaseio.com",
    projectId: "buenas-migas-c15ad",
    storageBucket: "buenas-migas-c15ad.appspot.com",
    messagingSenderId: "159702822088",
    appId: "1:159702822088:web:d08a43830d90cd050909fd",
    measurementId: "G-BJ55M19S2P"
  }
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
