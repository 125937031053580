export class Jumbo {

    private title: string;
    private description: string;
    private buttonLabel: string;
    private background: string;
    private path: string;
    private type: string;

    constructor(){

    }
    /**
     * Getter $title
     * @return {string}
     */
	public get $title(): string {
		return this.title;
	}

    /**
     * Getter $description
     * @return {string}
     */
	public get $description(): string {
		return this.description;
	}

    /**
     * Getter $buttonLabel
     * @return {string}
     */
	public get $buttonLabel(): string {
		return this.buttonLabel;
	}

    /**
     * Getter $background
     * @return {string}
     */
	public get $background(): string {
		return this.background;
	}

    /**
     * Getter $path
     * @return {string}
     */
	public get $path(): string {
		return this.path;
	}

    /**
     * Getter $type
     * @return {string}
     */
	public get $type(): string {
		return this.type;
	}

    /**
     * Setter $title
     * @param {string} value
     */
	public set $title(value: string) {
		this.title = value;
	}

    /**
     * Setter $description
     * @param {string} value
     */
	public set $description(value: string) {
		this.description = value;
	}

    /**
     * Setter $buttonLabel
     * @param {string} value
     */
	public set $buttonLabel(value: string) {
		this.buttonLabel = value;
	}

    /**
     * Setter $background
     * @param {string} value
     */
	public set $background(value: string) {
		this.background = value;
	}

    /**
     * Setter $path
     * @param {string} value
     */
	public set $path(value: string) {
		this.path = value;
	}

    /**
     * Setter $type
     * @param {string} value
     */
	public set $type(value: string) {
		this.type = value;
	}


}