import { Component, OnInit,Input} from '@angular/core';

@Component({
  selector: 'bm-feature-card',
  templateUrl: './feature-card.component.html',
  styleUrls: ['./feature-card.component.scss']
})
export class FeatureCardComponent implements OnInit {

  constructor() { }
  @Input()  title:string;
  @Input() description:string;
  @Input() icon:string;
  ngOnInit(): void {
  }

}
