import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'bm-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  @Input() image: string;
  @Input() title: string;
  @Input() price: string;
  @Input() promotion_price: string;
  @Input() onDemand: boolean;
  finalPrice:string;
  displayPromotion: boolean = false;
  

  constructor() {


  }

  ngOnInit(): void {

    if ((this.promotion_price !== "") && (this.promotion_price !== "0")) {
      this.displayPromotion = true;
    }

    if (this.displayPromotion == true) {
      this.finalPrice = this.promotion_price;
    } else {
      this.finalPrice = this.price;
    }
  }

}
