import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bm-about-us-global',
  templateUrl: './about-us-global.component.html',
  styleUrls: ['./about-us-global.component.scss']
})
export class AboutUsGlobalComponent implements OnInit {

  constructor() { }

  title = "MISIÓN"
  description = "Somos una empresa de repostería que ofrece productos variados de la más alta calidad con un excelente sabor y aun precio accesible, utilizando las mejores materias primas del mercado para satisfacer a nuestros clientes con productos innovadores y únicos."
  itemsList = [];
  ngOnInit(): void {
  }
  onPressed(text: string) {
    this.title = text

    if (text == 'MISIÓN') {
      this.description = 'Somos una empresa de repostería que ofrece productos variados de la más alta calidad con un excelente sabor y aun precio accesible, utilizando las mejores materias primas del mercado para satisfacer a nuestros clientes con productos innovadores y únicos.'
      this.itemsList = [];
    }

    if (text == 'VISIÓN') {
      this.description = 'Ser una empresa líder en la ciudad de Sucre en el negocio de repostería reconocida por su calidad y sabores únicos de nuestros productos, ofreciendo un servicio amable, oportuno y con precios accesibles.'
      this.itemsList = [];
    }

    if (text == 'VALORES') {
      this.description = "";
      this.itemsList = ["Honestidad",
        "Responsabilidad",
        "Respeto",
        "Cordialidad"
      ]
    }
  }
}
