import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { NavComponent } from './components/nav/nav.component';
import { NavItemComponent } from './components/nav-item/nav-item.component';
import { EnterpriseInfoComponent } from './components/enterprise-info/enterprise-info.component';
import { ServiceCardComponent } from './components/service-card/service-card.component';
import { OurServicesComponent } from './components/our-services/our-services.component';
import { ProductComponent } from './components/product/product.component';
import { CategoryComponent } from './components/category/category.component';
import { ButtonComponent } from './components/button/button.component';
import { JumbotronComponent } from './components/jumbotron/jumbotron.component';
import { FeatureCardComponent } from './components/feature-card/feature-card.component';
import { FeaturesComponent } from './components/features/features.component';
import { AboutUsCardComponent } from './components/about-us-card/about-us-card.component';
import { AboutUsaboutUsComponent } from './components/about-usabout-us/about-usabout-us.component';
import { AboutUsSelectorComponent } from './components/about-us-selector/about-us-selector.component';
import { AboutUsGlobalComponent } from './components/about-us-global/about-us-global.component';
import { IconButtonComponent } from './components/icon-button/icon-button.component';
import { FooterComponent } from './components/footer/footer.component';
import { CatalogComponent } from './pages/catalog/catalog.component';
import { HomeComponent } from './pages/home/home.component';
import { RouterModule } from '@angular/router';
import { CircleButtonComponent } from './components/circle-button/circle-button.component';
import { ChatComponent } from './components/chat/chat.component';
import { ProductDetailComponent } from './pages/product-detail/product-detail.component';
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { environment } from 'src/environments/environment';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    NavItemComponent,
    EnterpriseInfoComponent,
    ServiceCardComponent,
    OurServicesComponent,
    ProductComponent,
    CategoryComponent,
    ButtonComponent,
    JumbotronComponent,
    FeatureCardComponent,
    FeaturesComponent,
    AboutUsCardComponent,
    AboutUsaboutUsComponent,
    AboutUsSelectorComponent,
    AboutUsGlobalComponent,
    IconButtonComponent,
    FooterComponent,
    CatalogComponent,
    HomeComponent,
    CircleButtonComponent,
    ChatComponent,
    ProductDetailComponent
  ],
  imports: [
    AngularFireAnalyticsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    BrowserModule,
    RouterModule.forRoot([
      {path: 'catalogo/:category', component: CatalogComponent},
      {path: 'home', component: HomeComponent},
      {path: '', component: HomeComponent},
      {path:'producto/:productCode',component:ProductDetailComponent}
    ]),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }