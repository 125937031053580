<div id="enterprise">
    <button id="address" (click)="openUrl(basicInformation.$location)"> 
        <img src="/assets/images/map-marker.svg">
        <p>{{basicInformation.$address}}<br>{{basicInformation.$country}}</p>

    </button>


    <img routerLink=""  id="logo" src="/assets/images/LOGO_BUENAS_MIGAS.svg" alt="">

    <button id="address1" (click)="openUrl(basicInformation.$whatsapp)">
        <img src="/assets/images/whatsapp-orange.svg" alt="">
        <p>{{basicInformation.$cellphone}}</p>
    </button>

</div>