<bm-jumbotron [title]="cover.$title" description="{{cover.$description}}" buttonLabel="{{cover.$buttonLabel}}"
background="{{cover.$background}}" buttonPath="{{cover.$path}}"></bm-jumbotron>
<bm-category categoryName="MAS POPULARES" [products]="popularProducts"></bm-category>
<bm-jumbotron title="{{featured.$title}}"
    description="{{featured.$description}}"
    buttonLabel="{{featured.$buttonLabel}}" background="{{featured.$background}}" buttonPath="{{featured.$path}}">
</bm-jumbotron>
<bm-category categoryName="NUEVOS" [products]="newProducts"></bm-category>
<bm-our-services title="NUESTRAS DELICIAS"></bm-our-services>
<bm-about-us-global></bm-about-us-global>
<bm-features title="¿Por qué elegir Las Buenas Migas?"></bm-features>


